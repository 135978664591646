import React, { useMemo } from "react";

import { Modification, OnlineProduct, RefProduct } from "Types";
import {
    getRequiredDefaultModifications,
    getProductToDisplayAndOnlineProductType
} from "../../Utils/expressProductUtils";
import { EXPRESS_ONLINE_PRODUCT_TYPE } from "../../Utils/types";
import { BundleItemLayout, BundleItemWithModificationsLayout } from "./components";
import { usePosStore } from "Stores";

type Props = {
    product: OnlineProduct;
    isOutOfStock: boolean;
    selectedProductId: string;
    setSelectedProductId: (id: string | null) => void;
    handleSelectBundleItem: (product: OnlineProduct) => void;
    modificationProps: {
        isModifyMode: boolean;
        modsInModifyMode: Modification | null | undefined;
        modificationProductIdToEdit: string | null;
        setModificationProductIdToEdit: (id: string | null) => void;
        handleMustChooseModifications: (hasSingleMods: boolean) => void;
        handleSelectModifications: (modifications: Modification, hasFinished: boolean) => void;
    };
};

export const BundleItemProductCard: React.FC<Props> = ({
    product,
    isOutOfStock,
    selectedProductId,
    setSelectedProductId,
    handleSelectBundleItem,
    modificationProps
}) => {
    const { addons, refProducts } = usePosStore();

    const { modifications } = getRequiredDefaultModifications(product);
    const { productToDisplay, type } = useMemo(
        () => getProductToDisplayAndOnlineProductType(product, modifications, addons, refProducts),
        [product, modifications]
    );

    const handleBundleItem = () => {
        handleSelectBundleItem(product);
        setSelectedProductId(null);
    };

    const cardLayout = useMemo(() => {
        switch (type) {
            case EXPRESS_ONLINE_PRODUCT_TYPE.SIMPLE_REF_PRODUCT:
            case EXPRESS_ONLINE_PRODUCT_TYPE.REF_PRODUCT_WITH_ADDONS:
            case EXPRESS_ONLINE_PRODUCT_TYPE.REF_PRODUCT_WITH_SINGLE_MODIFICATION:
                return (
                    <BundleItemLayout
                        product={product}
                        productToDisplay={productToDisplay as RefProduct}
                        selectedProductId={selectedProductId}
                        isOutOfStock={isOutOfStock}
                        modifications={modifications}
                        setSelectedProductId={setSelectedProductId}
                        handleBundleItem={handleBundleItem}
                    />
                );
            case EXPRESS_ONLINE_PRODUCT_TYPE.REF_PRODUCT_WITH_MODIFICATIONS:
                return (
                    <BundleItemWithModificationsLayout
                        product={product}
                        productToDisplay={productToDisplay as RefProduct}
                        selectedProductId={selectedProductId}
                        isOutOfStock={isOutOfStock}
                        setSelectedProductId={setSelectedProductId}
                        handleSelectBundleItem={handleSelectBundleItem}
                        modificationProps={modificationProps}
                    />
                );

            default:
                return null;
        }
    }, [product, productToDisplay, selectedProductId, isOutOfStock, modifications]);
    return cardLayout;
};
