import React, { useMemo } from "react";

import { Box } from "Atoms";
import { OnlineProduct, OnlineModifications, RefProduct } from "Types";
import { useTheme } from "ThemeProvider";
import { useLanguage } from "LanguageProvider";
import { ProductCardBase } from "./ProductCardBase";
import { ProductCardFooterBundleItem } from "./ProductCardFooterSelected";
import { useQoplaStore } from "Stores";
import { getPriceWithCurrencyForBundleItemProduct } from "../../../Utils/expressProductUtils";

type Props = {
    product: OnlineProduct;
    productToDisplay: RefProduct;
    selectedProductId: string | null;
    isOutOfStock: boolean;
    setSelectedProductId: (id: string | null) => void;
    handleBundleItem: () => void;
    modifications?: OnlineModifications.ModificationsToUse | null;
};

export const BundleItemLayout: React.FC<Props> = ({
    product,
    productToDisplay,
    selectedProductId,
    isOutOfStock,
    modifications = null,
    setSelectedProductId,
    handleBundleItem
}) => {
    const {
        orientation: { isPortrait }
    } = useTheme();
    const { companyLocale } = useQoplaStore();
    const { translate } = useLanguage();

    const isSelected = selectedProductId === product.id;

    const productPriceWithCurrency = useMemo(
        () => getPriceWithCurrencyForBundleItemProduct(product, modifications, companyLocale, translate),
        [product]
    );

    const footerProps = {
        productName: productToDisplay.name,
        productPrice: productPriceWithCurrency,
        wasAdded: false, //not used for bundle items
        isPortrait: isPortrait
    };

    const onHandleProductSelection = (productId: string) => {
        setSelectedProductId(isSelected ? null : productId);
    };
    return (
        <Box>
            <ProductCardBase
                product={product}
                productToDisplay={productToDisplay}
                productPrice={productPriceWithCurrency}
                isOutOfStock={isOutOfStock}
                canToggleSelected={!isOutOfStock}
                showInfoBanner={isSelected}
                startAnimation={false}
                isSelected={isSelected}
                onHandleProductSelection={onHandleProductSelection}
            >
                {isSelected && (
                    <ProductCardFooterBundleItem handleAddProductClick={handleBundleItem} {...footerProps} />
                )}
            </ProductCardBase>
        </Box>
    );
};
