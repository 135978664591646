import React, { useState, useEffect } from "react";

import { OnlineProduct, OnlineModifications, Modification, RefProduct } from "Types";
import { useTheme } from "ThemeProvider";
import { useLanguage } from "LanguageProvider";
import { ProductCardBase } from "./ProductCardBase";
import { useOrderWindowsStore, useQoplaStore } from "Stores";
import {
    getMenuProductPriceWithModifications,
    getRequiredDefaultModifications,
    haveModificationsBeenSelected
} from "../../../Utils/expressProductUtils";
import { ProductCardFooterSimpleMods, ProductModifications } from ".";

type Props = {
    product: OnlineProduct;
    productToDisplay: RefProduct;
    selectedProductId: string | null;
    isOutOfStock: boolean;
    setSelectedProductId: (id: string | null) => void;
    handleSelectBundleItem: (product: OnlineProduct) => void;
    modificationProps: {
        isModifyMode: boolean;
        modsInModifyMode: Modification | null | undefined;
        modificationProductIdToEdit: string | null;
        setModificationProductIdToEdit: (id: string | null) => void;
        handleMustChooseModifications: (hasSingleMods: boolean) => void;
        handleSelectModifications: (modifications: Modification, hasFinished: boolean) => void;
    };
};

export const BundleItemWithModificationsLayout: React.FC<Props> = ({
    product,
    productToDisplay,
    selectedProductId,
    isOutOfStock,
    setSelectedProductId,
    handleSelectBundleItem,
    modificationProps
}) => {
    const [openModifications, setOpenModifications] = useState(false);
    const [selectedModifications, setSelectedModifications] =
        useState<OnlineModifications.SelectedModifications | null>(null);
    const [selectedProductModId, setSelectedProductModId] = useState<string | null>(null);

    const { selectedCartProductWithMods, setSelectedCartProductWithMods } = useOrderWindowsStore();

    const { companyLocale } = useQoplaStore();
    const { translate } = useLanguage();
    const {
        orientation: { isPortrait }
    } = useTheme();

    const {
        modificationProductIdToEdit,
        setModificationProductIdToEdit,
        handleMustChooseModifications,
        handleSelectModifications,
        isModifyMode = false,
        modsInModifyMode
    } = modificationProps || {};

    const isSelected = selectedProductId === product.id;
    const selectedModProductInEdit = product.id === selectedCartProductWithMods?.menuProduct?.id;

    useEffect(() => {
        if (selectedModProductInEdit) {
            setOpenModifications(true);
            setSelectedModifications(
                selectedCartProductWithMods?.orderProduct.modifications as OnlineModifications.SelectedModifications
            );
        }
    }, [selectedCartProductWithMods]);

    useEffect(() => {
        if (!isSelected) {
            setOpenModifications(false);
            setSelectedModifications(null);
        }
    }, [selectedProductId]);

    /** In order to go back in bundle creator to a bundle item with mods! */
    useEffect(() => {
        if (
            isModifyMode === true &&
            isSelected &&
            selectedProductId === modificationProductIdToEdit &&
            !!modifications &&
            !!modsInModifyMode
        ) {
            setSelectedProductModId(modificationProductIdToEdit);
            setOpenModifications(true);
            setSelectedModifications(modsInModifyMode as OnlineModifications.SelectedModifications);
        }
    }, [isModifyMode === true]);

    const { modifications, requiredMods } = getRequiredDefaultModifications(product);

    const { hasStartingPrice, priceText, mustChooseOptions } = getMenuProductPriceWithModifications(
        productToDisplay.defaultPrice,
        productToDisplay.priceType,
        true, // isBundleItem
        modifications!,
        companyLocale
    );

    const hasSingleMods = !mustChooseOptions;
    const productPriceWithCurrency = hasStartingPrice ? `${translate("from")} ${priceText}` : priceText;

    const onHandleProductModifications = (selectedProductId: string) => {
        const modificationsFinished = haveModificationsBeenSelected(requiredMods, selectedModifications);
        const canAddProductWithMods =
            (openModifications || hasSingleMods) &&
            !!selectedProductModId &&
            !!selectedModifications &&
            modificationsFinished;

        const canAddProductWithModsAfterModify =
            hasSingleMods && modificationsFinished && !!selectedProductModId && !!selectedModifications;

        if (canAddProductWithMods || canAddProductWithModsAfterModify) {
            addProductWithModsToCart();
        } else if (!openModifications) {
            setOpenModifications(true);
            setSelectedProductModId(selectedProductId);
            setSelectedProductId(selectedProductId);
        }
    };

    const handleSelectProduct = (productId: string) => {
        setSelectedProductId(productId);
        setSelectedProductModId(productId);
        handleMustChooseModifications(!hasSingleMods && !!modifications);
    };

    const addProductWithModsToCart = () => {
        handleSelectBundleItem(product);
        setModificationProductIdToEdit(null);
        setSelectedProductId(null);
        setSelectedProductModId(null);
        setOpenModifications(false);
        setSelectedModifications(null);
        setSelectedCartProductWithMods(null);
    };

    const onHandleSelectingModifications = (selectedModifications: OnlineModifications.SelectedModifications) => {
        setSelectedModifications(selectedModifications);

        const hasFinished = haveModificationsBeenSelected(requiredMods, selectedModifications, selectedModifications);

        handleSelectModifications(selectedModifications as Modification, hasFinished);
        handleMustChooseModifications(!hasFinished);
    };
    const footerProps = {
        productName: productToDisplay.name,
        productPrice: productPriceWithCurrency,
        wasAdded: false,
        isPortrait: isPortrait
    };

    const showInfoBanner = !openModifications && isSelected;

    return (
        <ProductCardBase
            product={product}
            productToDisplay={productToDisplay}
            productPrice={productPriceWithCurrency}
            isOutOfStock={isOutOfStock}
            canToggleSelected={!isOutOfStock && !openModifications}
            showInfoBanner={showInfoBanner}
            startAnimation={false}
            isSelected={isSelected}
            onHandleProductSelection={handleSelectProduct}
        >
            {openModifications && !!selectedProductModId && (
                <ProductModifications
                    //TODO: here selected mods from this point - so function to set selected mods and then checks for the bundle item against the required
                    setModificationOption={onHandleSelectingModifications}
                    isPortrait={isPortrait}
                    isBundleItem={true}
                    selectedModifications={selectedModifications}
                    modificationsToUse={modifications!}
                />
            )}
            {isSelected ? (
                <ProductCardFooterSimpleMods
                    modificationIsOpen={openModifications}
                    isEditingMods={!!selectedProductModId && selectedModProductInEdit}
                    hasSingleMods={hasSingleMods}
                    handleAddProductClick={event => {
                        event.stopPropagation();
                        onHandleProductModifications(product.id);
                    }}
                    {...footerProps}
                />
            ) : null}
        </ProductCardBase>
    );
};
