import React, { useState, useEffect } from "react";

import { MenuBundleProduct, OnlineProduct, CartProduct, BundleItemOperation, RefBundleProduct } from "Types";
import { useTheme } from "ThemeProvider";
import { useLanguage } from "LanguageProvider";
import { BundleCategory } from "./BundleCategory";
import { ProductCardBase } from "./ProductCardBase";
import { ProductCardFooterSimpleBundle } from "./ProductCardFooterSelected";
import { useOrderWindowsStore, usePosStore, useQoplaStore } from "Stores";
import { tempBundleProductOrderProduct } from "../../../../poses/shared/utils/cartController";
import { hasBundleModificationPrice } from "../../../../../../admin/components/poses/pos/utils";
import { addToCartAnimation, showMessageAddedProduct } from "../../../Utils/expressProductUtils";
import { getPriceStringForMenuBundleProductWithLocale } from "PriceUtils";

type Props = {
    product: OnlineProduct;
    productToDisplay: RefBundleProduct;
    simpleBundleIdToEdit: string | null;
    selectedProductId: string | null;
    selectedOnlineCategoryId: string;
    isOutOfStock: boolean;
    setSelectedProductId: (id: string | null) => void;
    setSimpleBundleIdToEdit: (id: string | null) => void;
    isUpsell?: boolean;
};

export const SimpleBundleLayout: React.FC<Props> = ({
    product,
    productToDisplay,
    simpleBundleIdToEdit,
    selectedOnlineCategoryId,
    selectedProductId,
    isOutOfStock,
    setSelectedProductId,
    setSimpleBundleIdToEdit,
    isUpsell = false
}) => {
    const [isBundleCategoryOpen, setIsBundleCategoryOpen] = useState(false);
    const [selectedBundleItemId, setSelectedBundleItemId] = useState<string>("");
    const [wasAdded, setWasAdded] = useState(false);
    const [startAnimation, setStartAnimation] = useState(false);

    const {
        orientation: { isPortrait }
    } = useTheme();

    const { companyLocale, selectedShop } = useQoplaStore();
    const { refProducts } = usePosStore();

    const { selectedBundleCartProduct, setSelectedBundleCartProduct, CartManager, upsertBundleCartProductToCart } =
        useOrderWindowsStore();

    const { addBundleItemToBundleProduct } = CartManager;

    const { translate } = useLanguage();

    const isEditingSimpleBundle = simpleBundleIdToEdit === product.id;
    const isSelected = selectedProductId === product.id;

    useEffect(() => {
        if (isEditingSimpleBundle && !isBundleCategoryOpen) {
            setIsBundleCategoryOpen(true);
        }
        if (!isSelected && isBundleCategoryOpen) {
            setIsBundleCategoryOpen(false);
            setSimpleBundleIdToEdit("");
        }
    }, [selectedProductId, simpleBundleIdToEdit]);

    useEffect(() => {
        // checks if an simple bundle product should be added
        // added selectedBundleItemId to prevent the selected bundle product
        // from being added to the cart
        if (
            isUpsell &&
            !!selectedBundleCartProduct?.orderProduct?.selectedBundleProductItems?.[0].refProductId &&
            !isEditingSimpleBundle &&
            selectedBundleItemId
        ) {
            addSimpleBundleProductToCart();
        }
    }, [selectedBundleCartProduct]);

    const hasModPrice = hasBundleModificationPrice(product, refProducts);
    const extraText = hasModPrice ? `${translate("from")} ` : "";
    const productPriceWithCurrency = getPriceStringForMenuBundleProductWithLocale(
        product.menuBundleProduct!,
        companyLocale,
        extraText
    );

    const footerProps = {
        productName: productToDisplay.name,
        productPrice: productPriceWithCurrency,
        wasAdded: wasAdded,
        isPortrait: isPortrait
    };

    const handleProductSelection = (productId: string) => {
        setSelectedProductId(isSelected ? null : productId);
    };

    const addSimpleBundleProductToCart = () => {
        setIsBundleCategoryOpen(false);
        setSelectedBundleItemId("");
        showMessageAddedProduct(setWasAdded);
        addToCartAnimation(setStartAnimation);
        addSimpleBundleProduct();
    };

    const handleAddSimpleBundleProduct = () => {
        if (selectedBundleItemId && isBundleCategoryOpen) {
            addSimpleBundleProductToCart();
        } else {
            const tempBundleProduct = tempBundleProductOrderProduct(
                product.menuBundleProduct as MenuBundleProduct,
                selectedShop?.id!,
                selectedOnlineCategoryId,
                1,
                null,
                null
            );
            setIsBundleCategoryOpen(true);
            setSelectedBundleCartProduct(tempBundleProduct as CartProduct);
        }
    };

    const addSimpleBundleProduct = () => {
        if (isEditingSimpleBundle && selectedBundleCartProduct?.orderProduct?.selectedBundleProductItems) {
            const bundleCategory = selectedBundleCartProduct?.orderProduct?.selectedBundleProductItems[0];
            const productToAdd = refProducts[bundleCategory.refProductId as string];
            addBundleItemToBundleProduct(productToAdd, bundleCategory, null, false, null, BundleItemOperation.ADD);
        } else {
            upsertBundleCartProductToCart(
                selectedBundleCartProduct as CartProduct,
                1,
                undefined,
                null,
                [],
                false,
                isUpsell //isUpsell
            );
        }
        setSimpleBundleIdToEdit(null);
        setSelectedProductId(null);
        setSelectedBundleCartProduct(null);
    };

    const canToggleSelected = !isOutOfStock && !isBundleCategoryOpen && !isSelected;

    const showInfoBanner = !isBundleCategoryOpen && (isUpsell ? !isSelected : isSelected);

    return (
        <ProductCardBase
            product={product}
            productToDisplay={productToDisplay}
            productPrice={productPriceWithCurrency}
            isOutOfStock={isOutOfStock}
            canToggleSelected={canToggleSelected}
            showInfoBanner={showInfoBanner}
            isSelected={isSelected}
            startAnimation={startAnimation}
            onHandleProductSelection={handleProductSelection}
            isUpsell={isUpsell}
        >
            {isBundleCategoryOpen && (
                <BundleCategory
                    product={product as MenuBundleProduct}
                    selectedBundleItemId={selectedBundleItemId}
                    isPortrait={isPortrait}
                    setSelectedBundleItemId={setSelectedBundleItemId}
                />
            )}
            {(isSelected || isUpsell) && (
                <ProductCardFooterSimpleBundle
                    isBundleCategoryOpen={isBundleCategoryOpen}
                    handleAddProductClick={() => !wasAdded && handleAddSimpleBundleProduct()}
                    isEditingSimpleBundle={isEditingSimpleBundle}
                    isUpsell={isUpsell}
                    {...footerProps}
                />
            )}
        </ProductCardBase>
    );
};
