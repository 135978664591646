import React, { useMemo, useState } from "react";

import { Box } from "Atoms";
import {
    OnlineProduct,
    AddonsHashMapValue,
    OnlineModifications,
    Modification,
    RefBundleProduct,
    RefProduct
} from "Types";
import { useTheme } from "ThemeProvider";
import { useLanguage } from "LanguageProvider";
import { ProductCardBase } from "./ProductCardBase";
import { ProductCardFooterSelected } from "./ProductCardFooterSelected";
import { usePosStore, useQoplaStore } from "Stores";
import { EXPRESS_ONLINE_PRODUCT_TYPE } from "../../../Utils/types";
import {
    addToCartAnimation,
    showMessageAddedProduct,
    getPriceWithCurrencyForMenuProduct
} from "../../../Utils/expressProductUtils";

type Props = {
    product: OnlineProduct;
    productToDisplay: RefProduct | RefBundleProduct;
    type: EXPRESS_ONLINE_PRODUCT_TYPE;
    selectedProductId: string | null;
    isOutOfStock: boolean;
    setSelectedProductId: (id: string | null) => void;
    addProduct?: (product: OnlineProduct, modifications: Modification | null) => void;
    modifications?: OnlineModifications.ModificationsToUse | null;
    handleOpenProductPage?: (productAddons?: AddonsHashMapValue, isBundleProduct?: boolean) => void;
};

export const StaticProductLayout: React.FC<Props> = ({
    product,
    productToDisplay,
    type,
    selectedProductId,
    isOutOfStock,
    modifications = null,
    setSelectedProductId,
    handleOpenProductPage,
    addProduct
}) => {
    const [wasAdded, setWasAdded] = useState(false);
    const [startAnimation, setStartAnimation] = useState(false);

    const {
        orientation: { isPortrait }
    } = useTheme();
    const { companyLocale } = useQoplaStore();
    const { addons, refProducts } = usePosStore();
    const { translate } = useLanguage();

    const isSelected = selectedProductId === product.id;

    const isSelectedProduct = isSelected || wasAdded;

    const productAddons = productToDisplay && productToDisplay.id ? addons[productToDisplay.id] || [] : [];
    const hasAddons = productAddons.length > 0;

    const isBundleProduct = type === EXPRESS_ONLINE_PRODUCT_TYPE.BUNDLE_PRODUCT;

    const productPriceWithCurrency = useMemo(
        () => getPriceWithCurrencyForMenuProduct(product, modifications, refProducts, companyLocale, translate),
        [product]
    );

    const footerProps = {
        productName: productToDisplay.name,
        productPrice: productPriceWithCurrency,
        wasAdded: wasAdded,
        isPortrait: isPortrait
    };

    const handleAddProductClick = () => {
        if (!hasAddons && !isBundleProduct) {
            !!addProduct && addProduct(product, modifications as unknown as Modification);
            showMessageAddedProduct(setWasAdded);
            addToCartAnimation(setStartAnimation);
        } else {
            !!handleOpenProductPage && handleOpenProductPage(productAddons, isBundleProduct);
        }
    };

    const onHandleProductSelection = (productId: string) => {
        setSelectedProductId(isSelected ? null : productId);
    };
    return (
        <Box>
            <ProductCardBase
                product={product}
                productToDisplay={productToDisplay}
                productPrice={productPriceWithCurrency}
                isOutOfStock={isOutOfStock}
                canToggleSelected={!isOutOfStock}
                showInfoBanner={isSelected}
                startAnimation={startAnimation}
                isSelected={isSelected || wasAdded}
                onHandleProductSelection={onHandleProductSelection}
            >
                {isSelectedProduct && (
                    <ProductCardFooterSelected
                        handleAddProductClick={() => !wasAdded && handleAddProductClick()}
                        {...footerProps}
                    />
                )}
            </ProductCardBase>
        </Box>
    );
};
