import React, { PropsWithChildren } from "react";

import { Box } from "Atoms";
import { OnlineProduct } from "Types";
import { ProductCardFooter } from "./ProductCardFooter";
import { useTheme } from "ThemeProvider";
import { useLanguage } from "LanguageProvider";
import { ProductCardAnimation } from "./ProductCardAnimation";
import { ProductImage } from "./ProductImage";
import { ExpressProductInformationBanner } from "../ExpressProductInformationBanner";
import { useDrawer } from "Providers/drawerProvider";

type Props = {
    product: OnlineProduct;
    productToDisplay: any;
    productPrice: string;
    isOutOfStock: boolean;
    canToggleSelected: boolean;
    startAnimation: boolean;
    showInfoBanner: boolean;
    isSelected: boolean;
    onHandleProductSelection: (productId: string) => void;
    isUpsell?: boolean;
} & PropsWithChildren;

export const ProductCardBase: React.FC<Props> = ({
    product,
    productToDisplay,
    productPrice,
    isOutOfStock,
    canToggleSelected,
    startAnimation,
    showInfoBanner,
    isSelected,
    onHandleProductSelection,
    isUpsell = false,
    children
}) => {
    const {
        colors,
        orientation: { isPortrait }
    } = useTheme();
    const { translate } = useLanguage();
    const { onOpenDrawer } = useDrawer();

    const getBorderColor = () => {
        if (isOutOfStock) {
            return colors.expressLightGrey;
        }
        if (isSelected) {
            return colors.expressPrimary;
        }
        return colors.expressSecondaryDark;
    };
    const { imageUrl, imageResizedUrls, name } = productToDisplay;
    const productImage = imageResizedUrls?.medium || imageUrl;
    return (
        <Box
            h={isPortrait ? "380px" : "330px"}
            w={isPortrait ? "300px" : "260px"}
            bg={isOutOfStock ? "expressLightGrey" : colors.expressSecondary}
            borderRadius={"1.25rem"}
            position={"relative"}
            border={`3px solid`}
            borderColor={getBorderColor()}
            cursor={"pointer"}
            id={product.id}
            onClick={() => canToggleSelected && onHandleProductSelection(product.id)}
        >
            <ProductImage
                productImage={productImage}
                isOutOfStock={isOutOfStock}
                productName={name}
                isPortrait={isPortrait}
            />
            <ProductCardAnimation imageUrl={productImage} startAnimation={startAnimation} />
            {isOutOfStock && (
                <Box
                    position={"absolute"}
                    top={isPortrait ? "121px" : "110px"}
                    left={isPortrait ? "21px" : "8px"}
                    minH="52px"
                    w={isPortrait ? "262px" : "235px"}
                    color={"black"}
                    fontWeight={"600"}
                    fontSize={isPortrait ? "2xl" : "xl"}
                    bg="white"
                    borderRadius={"2.5rem"}
                    zIndex={1}
                    py={2}
                    px={8}
                    textAlign={"center"}
                >
                    {translate("tempOutOfStock")}
                </Box>
            )}
            {showInfoBanner && (
                <ExpressProductInformationBanner
                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                        e.stopPropagation();
                        onOpenDrawer("expressProductInformationDrawer", { product: product });
                    }}
                    border={"3px solid"}
                    borderColor={isUpsell ? colors.expressSecondaryDark : colors.expressPrimary}
                    position={"absolute"}
                    left={"-3px"}
                    top={isPortrait ? "164px" : "142px"}
                    bg={"white"}
                    height={isPortrait ? "64px" : "55px"}
                    width={isPortrait ? "72px" : "62px"}
                    showInfoText
                />
            )}
            <ProductCardFooter
                productName={name}
                productPrice={productPrice}
                isOutOfStock={isOutOfStock}
                isPortrait={isPortrait}
            />
            {children}
        </Box>
    );
};
