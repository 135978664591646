import React from "react";
import { IoIosInformationCircleOutline } from "@react-icons/all-files/io/IoIosInformationCircleOutline";

import { Box, Flex, FlexProps, PseudoBoxProps, Text } from "Atoms";
import { useTheme } from "ThemeProvider";
import { useLanguage } from "LanguageProvider";

type Props = {
    showInfoText?: boolean;
} & PseudoBoxProps;

export const ExpressProductInformationBanner: React.FC<Props> = ({ showInfoText = false, ...props }) => {
    const {
        colors,
        orientation: { isPortrait }
    } = useTheme();

    const { translate } = useLanguage();

    const containerStyles: Partial<FlexProps> = {
        justifyContent: "space-evenly",
        alignItems: "center",
        border: "3px solid",
        borderColor: colors.expressPrimary,
        borderRadius: "0 40px 40px 0",
        pr: showInfoText ? 3 : 2,
        pl: 1,
        py: 1,
        overflow: "hidden",
        cursor: "pointer"
    };

    return (
        <Flex {...containerStyles} {...props} {...(showInfoText && { width: "max-content" })}>
            <Box as={IoIosInformationCircleOutline} w="40px" h="40px" color={colors.expressPrimary} />
            {showInfoText && (
                <Text fontSize={isPortrait ? "sm" : "xs"} fontWeight="600" m="0" ml={1} mb={1}>
                    {translate("moreInformation")}
                </Text>
            )}
        </Flex>
    );
};
