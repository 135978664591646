import React, { useState, useEffect } from "react";

import { OnlineProduct, OnlineModifications, Modification, RefProduct } from "Types";
import { useTheme } from "ThemeProvider";
import { useLanguage } from "LanguageProvider";
import { ProductCardBase } from "./ProductCardBase";
import { useOrderWindowsStore, usePosStore, useQoplaStore } from "Stores";
import {
    getMenuProductPriceWithModifications,
    getRequiredDefaultModifications,
    showMessageAddedProduct,
    addToCartAnimation,
    haveModificationsBeenSelected
} from "../../../Utils/expressProductUtils";
import { ProductCardFooterSimpleMods, ProductModifications } from "./";

type Props = {
    product: OnlineProduct;
    productToDisplay: RefProduct;
    selectedProductId: string | null;
    isOutOfStock: boolean;
    setSelectedProductId: (id: string | null) => void;
    setModificationProductIdToEdit: (id: string | null) => void;
    addProduct?: (product: OnlineProduct, modifications: Modification | null) => void;
    isUpsell?: boolean;
};

export const ProductWithModificationsLayout: React.FC<Props> = ({
    product,
    productToDisplay,
    selectedProductId,
    isOutOfStock,
    setSelectedProductId,
    setModificationProductIdToEdit,
    addProduct,
    isUpsell = false
}) => {
    const [openModifications, setOpenModifications] = useState(false);
    const [selectedModifications, setSelectedModifications] =
        useState<OnlineModifications.SelectedModifications | null>(null);
    const [startAnimation, setStartAnimation] = useState(false);

    const { selectedCartProductWithMods, setSelectedCartProductWithMods } = useOrderWindowsStore();

    const { companyLocale } = useQoplaStore();
    const { addons } = usePosStore();
    const { translate } = useLanguage();
    const [wasAdded, setWasAdded] = useState(false);

    const {
        orientation: { isPortrait }
    } = useTheme();

    const isSelected = selectedProductId === product.id;
    const selectedModProductInEdit = product.id === selectedCartProductWithMods?.menuProduct?.id;

    useEffect(() => {
        if (isUpsell) {
            const modificationsFinished = haveModificationsBeenSelected(requiredMods, selectedModifications);
            const canAddProductWithMods = isSelected && !!selectedModifications && modificationsFinished;

            const canAddProductWithModsAfterModify =
                modificationsFinished && !!selectedProductId && !!selectedModifications;
            if ((canAddProductWithMods || canAddProductWithModsAfterModify) && !selectedModProductInEdit) {
                addProductWithModsToCart();
            }
        }
    }, [selectedModifications]);

    useEffect(() => {
        if (selectedModProductInEdit) {
            setOpenModifications(true);
            setSelectedModifications(
                selectedCartProductWithMods?.orderProduct.modifications as OnlineModifications.SelectedModifications
            );
        }
    }, [selectedCartProductWithMods]);

    useEffect(() => {
        if (!isSelected) {
            setOpenModifications(false);
            setSelectedModifications(null);
        }
    }, [selectedProductId]);

    const { modifications, requiredMods } = getRequiredDefaultModifications(product);

    const { hasStartingPrice, priceText, mustChooseOptions } = getMenuProductPriceWithModifications(
        productToDisplay.defaultPrice,
        productToDisplay.priceType,
        false,
        modifications!,
        companyLocale
    );

    const productHasAddons = !!addons[productToDisplay.id];
    const hasSingleMods = !mustChooseOptions;
    const prioModsOverAddons = productHasAddons && !hasSingleMods;
    const productPriceWithCurrency = hasStartingPrice ? `${translate("from")} ${priceText}` : priceText;
    const productAddons = productToDisplay && productToDisplay.id ? addons[productToDisplay.id] || [] : [];

    const hasAddons = prioModsOverAddons ? false : !!productAddons.length;

    /** Simple product with mods & no addons!  */
    const isSimpleProductWithManyMods = !!modifications && !hasAddons;

    const onHandleProductModifications = (productId: string) => {
        if (isUpsell && !selectedProductId) {
            setSelectedProductId(product.id);
        }
        const modificationsFinished = haveModificationsBeenSelected(requiredMods, selectedModifications);
        const canAddProductWithMods =
            (openModifications || hasSingleMods) && isSelected && !!selectedModifications && modificationsFinished;

        const canAddProductWithModsAfterModify =
            hasSingleMods && modificationsFinished && !!productId && !!selectedModifications;

        if (canAddProductWithMods || canAddProductWithModsAfterModify) {
            addProductWithModsToCart();
        } else if (!openModifications) {
            setOpenModifications(true);
        }
    };

    const handleSelectProduct = (productId: string) => {
        setSelectedProductId(productId);
    };

    const addProductWithModsToCart = () => {
        const modsToUse = selectedModifications as Modification;

        !!addProduct && addProduct(product, modsToUse);
        showMessageAddedProduct(setWasAdded);
        addToCartAnimation(setStartAnimation);

        !!setModificationProductIdToEdit && setModificationProductIdToEdit(null);
        setSelectedProductId(null);
        setOpenModifications(false);
        setSelectedModifications(null);
        setSelectedCartProductWithMods(null);
    };

    const onHandleSelectingModifications = (selectedModifications: OnlineModifications.SelectedModifications) => {
        setSelectedModifications(selectedModifications);
    };
    const footerProps = {
        productName: productToDisplay.name,
        productPrice: productPriceWithCurrency,
        wasAdded: wasAdded,
        isPortrait: isPortrait
    };

    const shouldShowModsFooter = (isSelected && isSimpleProductWithManyMods) || isUpsell;

    const showInfoBanner = !openModifications && (isSelected || isUpsell);
    return (
        <ProductCardBase
            product={product}
            productToDisplay={productToDisplay}
            productPrice={productPriceWithCurrency}
            isOutOfStock={isOutOfStock}
            canToggleSelected={!isOutOfStock}
            showInfoBanner={showInfoBanner}
            startAnimation={startAnimation}
            isSelected={isSelected}
            onHandleProductSelection={handleSelectProduct}
            isUpsell={isUpsell}
        >
            {openModifications && (
                <ProductModifications
                    //TODO: here selected mods from this point - so function to set selected mods and then checks for the bundle item against the required
                    setModificationOption={onHandleSelectingModifications}
                    isPortrait={isPortrait}
                    isBundleItem={false}
                    selectedModifications={selectedModifications}
                    modificationsToUse={modifications!}
                />
            )}
            {shouldShowModsFooter ? (
                <ProductCardFooterSimpleMods
                    modificationIsOpen={openModifications}
                    isEditingMods={isSelected && selectedModProductInEdit}
                    hasSingleMods={hasSingleMods}
                    handleAddProductClick={event => {
                        event.stopPropagation();
                        if (!wasAdded) {
                            onHandleProductModifications(product.id);
                        }
                    }}
                    isUpsell={isUpsell}
                    {...footerProps}
                />
            ) : null}
        </ProductCardBase>
    );
};
