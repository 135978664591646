import React from "react";
import { FaPlus } from "@react-icons/all-files/fa/FaPlus";
import { FaArrowRight } from "@react-icons/all-files/fa/FaArrowRight";
import { IconType } from "@react-icons/all-files";

import { Box, Flex } from "Atoms";
import { NewIconButtonProps, NewIconButton } from "Atoms";
import { useLanguage } from "LanguageProvider";
import { useTheme } from "ThemeProvider";
import { getTextColor, isDarkColor } from "Utils";
import { TruncatedText } from "../../shared/TruncatedText";

type ProductCardFooterSimpleBundle = {
    isBundleCategoryOpen: boolean;
    handleAddProductClick: () => void;
    isEditingSimpleBundle: boolean;
    isUpsell?: boolean;
} & SelectedFooterProps;

export const ProductCardFooterSimpleBundle: React.FC<ProductCardFooterSimpleBundle> = ({
    isBundleCategoryOpen,
    handleAddProductClick,
    isEditingSimpleBundle,
    isUpsell = false,
    ...props
}) => {
    const { translate } = useLanguage();
    let buttonText = isBundleCategoryOpen || isUpsell ? translate("add") : translate("selectOption");
    if (isEditingSimpleBundle) {
        buttonText = translate("update");
    }
    return (
        <ProductCardFooterSelected
            buttonText={buttonText}
            buttonIcon={isBundleCategoryOpen || isUpsell ? FaPlus : FaArrowRight}
            handleAddProductClick={handleAddProductClick}
            isUpsell={isUpsell}
            {...props}
        />
    );
};

type ProductCardFooterBundleItem = {
    handleAddProductClick: () => void;
} & SelectedFooterProps;

export const ProductCardFooterBundleItem: React.FC<ProductCardFooterBundleItem> = ({
    handleAddProductClick,
    ...props
}) => {
    const { translate } = useLanguage();
    return (
        <ProductCardFooterSelected
            buttonText={translate("choose")}
            buttonIcon={FaArrowRight}
            handleAddProductClick={handleAddProductClick}
            {...props}
        />
    );
};

type ProductSimpleMods = {
    modificationIsOpen: boolean;
    isEditingMods: boolean;
    hasSingleMods: boolean;
    handleAddProductClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    isUpsell?: boolean;
} & SelectedFooterProps;

export const ProductCardFooterSimpleMods: React.FC<ProductSimpleMods> = ({
    modificationIsOpen,
    isEditingMods,
    hasSingleMods,
    handleAddProductClick,
    isUpsell = false,
    ...props
}) => {
    const { translate } = useLanguage();
    let buttonText = modificationIsOpen || hasSingleMods ? translate("add") : translate("selectOption");

    if (isEditingMods) {
        buttonText = translate("update");
    }
    return (
        <ProductCardFooterSelected
            buttonText={buttonText}
            buttonIcon={modificationIsOpen || hasSingleMods ? FaPlus : FaArrowRight}
            handleAddProductClick={handleAddProductClick}
            isUpsell={isUpsell}
            {...props}
        />
    );
};

type SelectedFooterProps = {
    productName: string;
    productPrice: string;
    wasAdded: boolean;
    isPortrait: boolean;
    handleAddProductClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    buttonIcon?: IconType;
    buttonText?: string;
    isUpsell?: boolean;
};

export const ProductCardFooterSelected: React.FC<SelectedFooterProps> = ({
    productName,
    productPrice,
    wasAdded,
    buttonText,
    isPortrait,
    buttonIcon,
    handleAddProductClick,
    isUpsell = false
}) => {
    const { translate } = useLanguage();
    const { colors } = useTheme();

    let iconBackgoundColor = colors.expressTextColor;
    if (isUpsell) {
        iconBackgoundColor = colors.expressPrimary;
    } else if (isDarkColor(colors.expressPrimary)) {
        iconBackgoundColor = "white";
    }

    const iconStyles: NewIconButtonProps = {
        rounded: "full",
        type: "button",
        fontSize: isPortrait ? "xl" : "md",
        height: "40px",
        width: "40px",
        backgroundColor: iconBackgoundColor,
        color: isUpsell ? "white" : colors.expressPrimary,
        _hover: {
            backgroundColor: iconBackgoundColor
        }
    };
    return (
        <Flex
            h={isPortrait ? "110px" : "95px"}
            w="100%"
            bg={isUpsell ? "white" : colors.expressPrimary}
            borderRadius={"0 0 0.875rem 0.875rem"}
            position="absolute"
            bottom={"0"}
            left={"0"}
            zIndex={2}
            direction={wasAdded ? "row" : "column"}
            color={getTextColor(isUpsell ? "white" : colors.expressPrimary)}
            alignItems={"center"}
            justifyContent={"center"}
            px={isPortrait ? 6 : 5}
            py={isPortrait ? 3 : 2}
            onClick={handleAddProductClick}
        >
            {wasAdded ? (
                <Box fontSize={"xl"}>{translate("addedProduct")}</Box>
            ) : (
                <>
                    <Flex justifyContent={"space-between"} w={"100%"}>
                        <TruncatedText my="auto" fontSize={isPortrait ? "sm" : "xs"} fontWeight={"600"}>
                            {productName}
                        </TruncatedText>
                        <Box my="auto" fontSize={isPortrait ? "xs" : "2xs"} minW={"50px"} textAlign={"right"}>
                            {productPrice}
                        </Box>
                    </Flex>
                    <Flex justifyContent={"space-between"} alignItems={"center"} w={"100%"}>
                        <Box my="auto" fontSize={isPortrait ? "xl" : "md"} fontWeight={"600"}>
                            {buttonText ?? translate("add")}
                        </Box>
                        <NewIconButton icon={buttonIcon ?? FaPlus} {...iconStyles} />
                    </Flex>
                </>
            )}
        </Flex>
    );
};
