import React, { useState } from "react";

import { Box } from "Atoms";
import {
    OnlineProduct,
    AddonsHashMapValue,
    OnlineModifications,
    Modification,
    RefProduct,
    RefBundleProduct
} from "Types";
import { useTheme } from "ThemeProvider";
import { useLanguage } from "LanguageProvider";
import { ProductCardBase } from "./ProductCardBase";
import { ProductCardFooterSelected } from "./ProductCardFooterSelected";
import { usePosStore, useQoplaStore } from "Stores";
import { EXPRESS_ONLINE_PRODUCT_TYPE } from "../../../Utils/types";
import {
    addToCartAnimation,
    showMessageAddedProduct,
    getPriceWithCurrencyForMenuProduct
} from "../../../Utils/expressProductUtils";

type Props = {
    product: OnlineProduct;
    productToDisplay: RefProduct | RefBundleProduct;
    type: EXPRESS_ONLINE_PRODUCT_TYPE;
    selectedProductId: string | null;
    isOutOfStock: boolean;
    setSelectedProductId: (id: string | null) => void;
    addProduct?: (product: OnlineProduct, modifications: Modification | null) => void;
    modifications?: OnlineModifications.ModificationsToUse | null;
    handleOpenProductPage?: (productAddons?: AddonsHashMapValue, isBundleProduct?: boolean, productId?: string) => void;
};

export const UpsellProductLayout: React.FC<Props> = ({
    product,
    productToDisplay,
    type,
    selectedProductId,
    isOutOfStock,
    modifications,
    setSelectedProductId,
    handleOpenProductPage,
    addProduct
}) => {
    const [wasAdded, setWasAdded] = useState(false);
    const [startAnimation, setStartAnimation] = useState(false);

    const {
        orientation: { isPortrait }
    } = useTheme();
    const { companyLocale } = useQoplaStore();
    const { addons, refProducts } = usePosStore();
    const { translate } = useLanguage();

    const isSelected = selectedProductId === product.id;

    const productAddons = productToDisplay && productToDisplay.id ? addons[productToDisplay.id] || [] : [];
    const hasAddons = productAddons.length > 0;

    const productPriceWithCurrency = getPriceWithCurrencyForMenuProduct(
        product,
        modifications ?? null,
        refProducts,
        companyLocale,
        translate
    );

    const isBundleProduct = type === EXPRESS_ONLINE_PRODUCT_TYPE.BUNDLE_PRODUCT;

    const footerProps = {
        productName: productToDisplay.name,
        productPrice: productPriceWithCurrency,
        wasAdded: wasAdded,
        isPortrait: isPortrait
    };

    // we either will add product in one click or open a separate view / drawer
    const hasNoSelectableOptionsInCart = [
        EXPRESS_ONLINE_PRODUCT_TYPE.REF_PRODUCT_WITH_SINGLE_MODIFICATION,
        EXPRESS_ONLINE_PRODUCT_TYPE.SIMPLE_REF_PRODUCT,
        EXPRESS_ONLINE_PRODUCT_TYPE.REF_PRODUCT_WITH_ADDONS,
        EXPRESS_ONLINE_PRODUCT_TYPE.BUNDLE_PRODUCT
    ].includes(type);

    const handleAddProductClick = () => {
        if (!hasAddons) {
            !!addProduct && addProduct(product, modifications as unknown as Modification);
            setSelectedProductId(null);
            addToCartAnimation(setStartAnimation);
            showMessageAddedProduct(setWasAdded);
        } else {
            !!handleOpenProductPage && handleOpenProductPage(productAddons, isBundleProduct, product.id);
        }
    };

    const onHandleProductSelection = (productId: string) => {
        setSelectedProductId(isSelected ? null : productId);
    };
    return (
        <Box>
            <ProductCardBase
                product={product}
                productToDisplay={productToDisplay}
                productPrice={productPriceWithCurrency}
                isOutOfStock={isOutOfStock}
                canToggleSelected={!isOutOfStock}
                showInfoBanner={!isSelected}
                startAnimation={startAnimation}
                isSelected={isSelected || wasAdded}
                onHandleProductSelection={() => !hasNoSelectableOptionsInCart && onHandleProductSelection}
                isUpsell
            >
                <ProductCardFooterSelected
                    handleAddProductClick={() => !wasAdded && handleAddProductClick()}
                    isUpsell={true}
                    {...footerProps}
                />
            </ProductCardBase>
        </Box>
    );
};
